import React, {useState, useEffect} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import AllTabs from "../../components/kit/Tabs/AllTabs";
import TelegramButton from "../../components/kit/Button/TelegramButton"
import copyIcon from "../../components/kit/icons/copy.svg";
import './FriendsScreen.css';

// {userData?.refs?.length > 0 ? userData.friends.sort((a, b) => b.points - a.points).map((item, i) => <li key={i}>{item.username}<span>{item.points}</span></li>) : ""}

// {friends.sort((a, b) => b.points - a.points).map((item, i) => <li key={i}>{item.username}<span>{item.points}</span></li>)}

const FriendsScreen = ({userData}) => {

    const friends = [{username: "Hello", points: 11284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},{username: "Hello", points: 1284},{username: "rfgeber", points: 4276},{username: "54h5676", points: 2296},{username: "56htfd76jh", points: 8288},]
    const share_text = encodeURIComponent("Earn $CUMBR with me!")

    const [userFriends, setUserFriends] = useState([])
        
    useEffect(() => {
        userData?.friends ? setUserFriends([...userData?.friends, userData, ...friends]) : setUserFriends([...friends])
    }, [userData])

    return (
        <TelegramScreen showbackbutton={true}>
            <TelegramHeader>
                <TelegramText className={'telegramTitle'}>Invite Friends!</TelegramText>
                <p className={'cooming_soon'}>You and your friend will receive <b>500 $CUMBR</b></p>
            </TelegramHeader>

            <div className={'leaderboard'}>
                <h3>Friends Leaderboard</h3>
                <ul>
                    {userFriends.sort((a, b) => b.points - a.points).map((item, i) => item.username==userData.username ? <b className={'user_top'} key={i}><li><span>{i+1}. {item.username}</span><span>{item.points}</span></li></b> : <li key={i}><span>{i+1}. {item.username}</span><span>{item.points}</span></li>)}
                </ul>
            </div>
            
            <div className='friennds_buttons'>
                <TelegramButton onClick={() => window.open(`https://t.me/share/url?url=https://t.me/coolcucumber_bot?start=${userData?.id}&text=${share_text}`, '_blank')} value="Invite Friends" className="invite_fr_button"/>
                <button onClick={() => navigator.clipboard.writeText(`https://t.me/coolcucumber_bot?start=${userData?.id}`)} className="telegramButton copy_invite"><img src={copyIcon}/></button>
            </div>

            <AllTabs/>

        </TelegramScreen>
    );
};

export default FriendsScreen;
