import React, {useEffect, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import AllTabs from "../../components/kit/Tabs/AllTabs";
import {sendLeaderboard} from "../../logic/server/HttpClient.js"
import userImage from "../../components/kit/icons/cumb_photo.jpg"
import './LeaderboardScreen.css';

const LeaderboardScreen = ({userData}) => {

    const friends = [ {"username": "User6", "points": 1060, "place": 6},
        {"username": "User7", "points": 1070, "place": 7},
        {"username": "User8", "points": 1080, "place": 8},
        {"username": "User9", "points": 1090, "place": 9},
        {"username": "User10", "points": 1100, "place": 10},
        {"username": "User11", "points": 1110, "place": 11},
        {"username": "User12", "points": 1120, "place": 12},
        {"username": "User13", "points": 1130, "place": 13},
        {"username": "User14", "points": 1140, "place": 14},
        {"username": "User15", "points": 1150, "place": 15},
        {"username": "User16", "points": 1160, "place": 16},
        {"username": "User17", "points": 1170, "place": 17},
        {"username": "User18", "points": 1180, "place": 18},
        {"username": "User19", "points": 1190, "place": 19},
        {"username": "User20", "points": 1200, "place": 20},
        {"username": "User21", "points": 1210, "place": 21},
        {"username": "User22", "points": 1220, "place": 22},
        {"username": "User23", "points": 1230, "place": 23},
        {"username": "User24", "points": 1240, "place": 24},
        {"username": "User25", "points": 1250, "place": 25},
        {"username": "User26", "points": 1260, "place": 26},
        {"username": "User27", "points": 1270, "place": 27},
        {"username": "User28", "points": 1280, "place": 28},
        {"username": "User29", "points": 1290, "place": 29},
        {"username": "User30", "points": 1300, "place": 30},
        {"username": "User31", "points": 1310, "place": 31},
        {"username": "User32", "points": 1320, "place": 32},
        {"username": "User33", "points": 1330, "place": 33},
        {"username": "User34", "points": 1340, "place": 34},
        {"username": "User35", "points": 1350, "place": 35},
        {"username": "User36", "points": 1360, "place": 36},
        {"username": "User37", "points": 1370, "place": 37},
        {"username": "User38", "points": 1380, "place": 38},
        {"username": "User39", "points": 1390, "place": 39},
        {"username": "User40", "points": 1400, "place": 40},
        {"username": "User41", "points": 1410, "place": 41},
        {"username": "User42", "points": 1420, "place": 42},
        {"username": "User43", "points": 1430, "place": 43},
        {"username": "User44", "points": 1440, "place": 44},
        {"username": "User45", "points": 1450, "place": 45},
        {"username": "User46", "points": 1460, "place": 46},
        {"username": "User47", "points": 1470, "place": 47},
        {"username": "User48", "points": 1480, "place": 48},
        {"username": "User49", "points": 1490, "place": 49},
        {"username": "User50", "points": 1500, "place": 50},
        {"username": "User51", "points": 1510, "place": 51},
        {"username": "User52", "points": 1520, "place": 52},
        {"username": "User53", "points": 1530, "place": 53},
        {"username": "User54", "points": 1540, "place": 54},
        {"username": "User55", "points": 1550, "place": 55},
        {"username": "User56", "points": 1560, "place": 56},
        {"username": "User57", "points": 1570, "place": 57},
        {"username": "User58", "points": 1580, "place": 58},
        {"username": "User59", "points": 1590, "place": 59},
        {"username": "User60", "points": 1600, "place": 60},
        {"username": "User61", "points": 1610, "place": 61},
        {"username": "User62", "points": 1620, "place": 62},
        {"username": "User63", "points": 1630, "place": 63},
        {"username": "User64", "points": 1640, "place": 64},
        {"username": "User65", "points": 1650, "place": 65},
        {"username": "User66", "points": 1660, "place": 66},
        {"username": "User67", "points": 1670, "place": 67},
        {"username": "User68", "points": 1680, "place": 68},
        {"username": "User69", "points": 1690, "place": 69},
        {"username": "User70", "points": 1700, "place": 70},
        {"username": "User71", "points": 1710, "place": 71},
        {"username": "User72", "points": 1720, "place": 72},
        {"username": "User73", "points": 1730, "place": 73},
        {"username": "User74", "points": 1740, "place": 74},
        {"username": "User75", "points": 1750, "place": 75},
        {"username": "User76", "points": 1760, "place": 76},
        {"username": "User77", "points": 1770, "place": 77},
        {"username": "User78", "points": 1780, "place": 78},
        {"username": "User79", "points": 1790, "place": 79},
        {"username": "User80", "points": 1800, "place": 80},
        {"username": "User81", "points": 1810, "place": 81},
        {"username": "User82", "points": 1820, "place": 82},
        {"username": "User83", "points": 1830, "place": 83},
        {"username": "User84", "points": 1840, "place": 84},
        {"username": "User85", "points": 1850, "place": 85},
        {"username": "User86", "points": 1860, "place": 86},
        {"username": "User87", "points": 1870, "place": 87},
        {"username": "User88", "points": 1880, "place": 88},
        {"username": "User89", "points": 1890, "place": 89},
        {"username": "User90", "points": 1900, "place": 90},
        {"username": "User91", "points": 1910, "place": 91},
        {"username": "User92", "points": 1920, "place": 92},
        {"username": "User93", "points": 1930, "place": 93},
        {"username": "User94", "points": 1940, "place": 94},
        {"username": "User95", "points": 1950, "place": 95},
        {"username": "User96", "points": 1960, "place": 96},
        {"username": "User96", "points": 1960, "place": 97},
        {"username": "User96", "points": 1960, "place": 98},
        {"username": "User96", "points": 1960, "place": 99},
        {"username": "User96", "points": 1960, "place": 100},
        {"username": "User96", "points": 1960, "place": 101},
        {"username": "User96", "points": 1960, "place": 102},]

    const [usersBoard, setUsersBoard] = useState([])
    const [usersRank, setUsersRank] = useState()

    const setRank = async () => {
        let rank = await usersBoard.find(user => user?.username === userData?.username)
        setUsersRank(rank)
    }

    useEffect(() => {
        const fetchUsers = async () => {
            const data = await sendLeaderboard();
            setUsersBoard([...data, ...friends, ]);  // Set the resolved data to state
        };
        fetchUsers(); // Call the async function
    }, []);

    useEffect(() => {
        setRank()
    }, [usersBoard])

    // const intervalId = setInterval(setRank, 2000);
 
    return (
        <TelegramScreen showbackbutton={true}>
            <TelegramHeader>
                <TelegramText className='telegramTitle'>Leaderboard 👑</TelegramText>
            </TelegramHeader>

            <div className="userboard">
                <div className="user_info"><img src={userImage}/><p>{userData.username}</p></div>
                <div className="user_rank"><p>Rank:</p><span>{usersRank?.place}</span></div>
                <div className="user_balance"><p>Balance:</p><p className='user_balance_amount'>{userData.points}</p><p className='user_balance_cumbr'>$CUMBR</p></div>
            </div>

            <div className='leaderboard leaderboard_general'>
            <ul>
                {usersBoard.slice(0, 100).map((user, index) => {
                    // Determine the class for top 3 users
                    let className = '';
                    if (index === 0) className = 'gold';
                    else if (index === 1) className = 'silver';
                    else if (index === 2) className = 'bronze';

                    return (
                    userData?.username === user?.username ? (
                        <b key={user?.place} className={`user_top ${className}`}>
                        <li>
                            <span>{user?.place}. {user?.username}</span>
                            <span>{user?.points}</span>
                        </li>
                        </b>
                    ) : (
                        <li key={user?.place} className={className}>
                        <span>{user?.place}. {user?.username}</span>
                        <span>{user?.points}</span>
                        </li>
                    )
                    );
                })}
                </ul>
            </div>
            
            <AllTabs/>

        </TelegramScreen>
    );
};

export default LeaderboardScreen;
