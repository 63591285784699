import React from 'react';
import checkIcon from "../../components/kit/icons/check_icon.png"

const SingleTask = ({task}) => {
    return (
            <div className={"sinngle_task " + task.className}>
                <div className='task_left_details'>
                    <img className={"task_image task_img_"+task.imgClass} src={task.image}/>
                    <div className="task_detail">
                        <h5>{task.name}</h5>
                        <p>{"+"+task.bonus+" $CUMBR"}</p>
                    </div>
                </div>
                {task.done ? <img className='check_task_icon' src={checkIcon}/> : (<div><button onClick={() => task.start()} className="telegramButton task_button">Start</button>
                <button onClick={() => task.check()} className="telegramButton task_button">Check</button></div>)}
            </div>
    )
};

export default SingleTask;
