import React from 'react';
import './AllTabs.css';
import SingleTab from "./SingleTab";
import {DEFAULT_PATH, PATH_LEADERBOARD, PATH_TASKS, PATH_FRIENDS, PATH_WALLET} from "../../../constants/Paths";
import leaderboardIcon from "../icons/leaderboard.svg";
import tasksIcon from "../icons/tasks.svg";
import homeIcon from "../icons/home.svg";
import walletIcon from "../icons/wallet.svg";
import friendsIcon from "../icons/friends.svg";

const AllTabs = () => {

    const listitems = [
        {
            icon: homeIcon,
            tabName: "Home",
            className: "tab_home",
            path: DEFAULT_PATH
        },
        {
            icon: tasksIcon,
            tabName: "Tasks",
            className: "tab_tasks",
            path: PATH_TASKS
        },
        {
            icon: leaderboardIcon,
            tabName: "Leaderboard",
            className: "tab_leaderboard",
            path: PATH_LEADERBOARD
        },
        {
            icon: friendsIcon,
            tabName: "Friends",
            className: "tab_friends",
            path: PATH_FRIENDS
        },
        {
            icon: walletIcon,
            tabName: "Wallet",
            className: "tab_wallet",
            path: PATH_WALLET
        }
    ] 

    return (
        <div className='all_tabs'>
            {listitems.map((item, i) =>  <SingleTab key={i} props={item}/>)}
        </div>
    );
};

export default AllTabs;