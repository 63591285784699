import API_URL from "./Variables"

export const sendLoginApp = async (body) => {
    const response = await fetch(`https://coolcumber.wildberry331.workers.dev/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    const data = await response.json();
    return data;
}

export const sendLeaderboard = async () => {
    const response = await fetch(`https://coolcumber.wildberry331.workers.dev/leaderboard`)
    const data = await response.json();
    return data;
}