import React, {useState, useEffect} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import AllTabs from "../../components/kit/Tabs/AllTabs";
import SingleTask from "./SingleTask";
import walletImage from "../../components/kit/icons/wallet_image.png"
import webImage from "../../components/kit/icons/browser_image.png"
import tgImage from "../../components/kit/icons/telegram_image.png"
import twitterImage from "../../components/kit/icons/twitter_image.png"
import ytImage from "../../components/kit/icons/youtube_image.png"
import validImage from "../../components/kit/icons/validating_image.png"
import { useNavigate } from 'react-router-dom';
import { useTonWallet } from '@tonconnect/ui-react';
import './TasksScreen.css';

const TasksScreen = () => {

    const [allTasks, setAllTasks] = useState([])
    const navigate = useNavigate()
    const wallet = useTonWallet();
    

    const goToSendTransactionPage = () => {
        navigate(`/wallet?transaction=true`);
    };

    const goToConnectWalletPage = () => {
        navigate(`/wallet?connect=true`);
    };
    
    const handleTransactionRequest = () => {
        if (wallet) {
            goToSendTransactionPage()
            return
        }
        goToConnectWalletPage()
    }

    const handleWalletRequest = () => {
        if (wallet) {
            return
        }
        goToConnectWalletPage()
    }

    const walletConnect = () => {
        console.log(123)
    }

    const checkWalletConnect = () => {
        console.log(321)
    }

    const tasks = [
        {
            image: walletImage,
            className: "wallet_connect",
            name: "Connect Wallet",
            bonus: 2000,
            start: handleWalletRequest,
            check: checkWalletConnect,
            imgClass: "wallet",
            done: false
        },
        {
            image: validImage,
            className: "validate_open",
            name: "Validate your wallet",
            bonus: 3000,
            start: handleTransactionRequest,
            check: checkWalletConnect,
            imgClass: "valid",
            done: false
        },
        {
            image: webImage,
            className: "web_open",
            name: "Check our plans",
            bonus: 300,
            start: walletConnect,
            check: checkWalletConnect,
            imgClass: "web",
            done: false
        },
        {
            image: tgImage,
            className: "telegram_open",
            name: "Follow Telegram",
            bonus: 300,
            start: walletConnect,
            check: checkWalletConnect,
            imgClass: "twitter",
            done: true
        },
        {
            image: twitterImage,
            className: "twitter_open",
            name: "Follow X",
            bonus: 300,
            start: walletConnect,
            check: checkWalletConnect,
            imgClass: "twitter",
            done: false
        }
    ]

    useEffect(() => {
        setAllTasks(tasks)
    }, [])

    return (
        <TelegramScreen showbackbutton={true}>
            <TelegramHeader>
                <TelegramText className={'telegramTitle'}>Tasks 📜</TelegramText>
            </TelegramHeader>

            <div className="user_tasks">
                {allTasks.map((task,i) => <SingleTask key={i} task={task}/>)}
            </div>
            
            <AllTabs/>

        </TelegramScreen>
    );
};

export default TasksScreen;
