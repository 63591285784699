import React, { useEffect, useState } from 'react';
import './LoginRewardPopup.css';

const rewardList = [100, 200, 400, 600, 1000, 1500, 2500];

const LoginRewardPopup = ({ loginDay }) => {
  const [reward, setReward] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // State to control popup visibility
  const [isHiding, setIsHiding] = useState(false); // State to trigger hiding animation
  

  useEffect(() => {
    if (loginDay >= 7) {
      setReward(rewardList[6]); // Days 7+ get the max reward
    } else {
      setReward(rewardList[loginDay - 1]); // Adjust for 0-based index
    }
  }, [loginDay]);

  const handleClick = () => {
    setIsHiding(true); // Trigger the hiding animation
    setTimeout(() => setIsVisible(false), 500); // Remove from DOM after animation
  };

  if (!isVisible) return null; // Don't render if not visible

  setTimeout(() => {
    handleClick()
  }, 5000)

  return (
    <div className={`popup ${isHiding ? 'popup-hide' : ''}`} onClick={handleClick}>
      <div className="popup-content">
        <h2 className="popup-header">🎉 Daily Reward 🎉</h2>
        <p className="popup-day">Day {loginDay}</p>
        <p className="popup-reward">{reward} <span>$CUMBR</span></p>

        {/* Emoji flying effect */}
        <div className="emoji-container">
          {Array.from({ length: 20 }).map((_, i) => (
            <span 
              key={i} 
              className="emoji-fly"
              style={{
                '--x': Math.random() > 0.5 ? Math.random() : -Math.random(),
                '--y': Math.random() > 0.5 ? Math.random() : -Math.random(),
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`
              }}>
              🎉
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoginRewardPopup;
