import React from 'react';
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import AllTabs from "../../components/kit/Tabs/AllTabs";
import {useTelegram} from "../../hooks/useTelegram";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import userImage from "../../components/kit/icons/cumb_photo.jpg"
import coinImage from "../../components/kit/icons/cucumber.jpg"
import './MainScreen.css';

const Main = ({userData}) => {
    const {user} = useTelegram()

    const rewards = [100, 200, 400, 600, 1000, 1500, 2500];

    return (
        <TelegramScreen showbackbutton={false}>

            <TelegramHeader>
                <TelegramText className={'telegramTitle'}>Welcome Cucumber!</TelegramText>
            </TelegramHeader>

            <div className={'welcome_user'}>
                
                <img src={userImage}/>
                <TelegramText>{user?.username}</TelegramText>
            </div>

            <div className={'points_user'}>
                <div>
                    <img src={coinImage}/>
                    <p className={'coin_name'}>$CUMBR</p>
                </div>
                <TelegramText className={'user_points_number'}>{userData?.points}</TelegramText>
            </div>

            <div className='daily_rewards_container'>
                <h3>Daily $CUMBR</h3>
                <div className="daily_rewards">
                    {rewards.map((reward, i) => {
                        if (userData?.daily > 7) {
                            if (i === 5) {
                            return (
                                <div key={i}>
                                <p style={{fontSize:"22px"}}>...</p>
                                </div>
                            );
                            }
                            if (i === 6) {
                            return (
                                <div className='current_daily' key={i}>
                                <p>{userData?.daily}</p><b>{reward}</b>
                                </div>
                            );
                            }
                        }
                        
                        return (
                            <div key={i} className={i+1 === userData?.daily ? 'current_daily' : ''}>
                            <p>{i+1}</p><b>{reward}</b>
                            </div>
                        );
                    })}
                </div>
            </div>

            <AllTabs/>

        </TelegramScreen>
    );
};

export default Main;
