import './App.css';
import {useCallback, useEffect, useState} from "react";
import {useTelegram} from "./hooks/useTelegram";
import {Route, Routes, useNavigate, useSearchParams} from "react-router-dom";
import Main from "./screens/main/MainScreen";
import {DEFAULT_PATH, PATH_LEADERBOARD, PATH_TASKS, PATH_FRIENDS, PATH_WALLET} from "./constants/Paths";
import FriendsScreen from "./screens/friends/FriendsScreen";
import LeaderboardScreen from "./screens/leaderboard/LeaderboardScreen";
import TasksScreen from "./screens/tasks/TasksScreen";
import WalletScreen from "./screens/wallet/WalletScreen";
import LoginRewardPopup from "./components/kit/LoginRewardPopup/LoginRewardPopup"
import {sendLoginApp} from "./logic/server/HttpClient.js"
import {TonConnectUIProvider} from '@tonconnect/ui-react';

function App() {
    const {user, webApp} = useTelegram()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [userData, setUserData] = useState({})
    // let code = window.Telegram.WebApp.initDataUnsafe.start_param
    let code = searchParams.get("code")

    document.addEventListener('DOMContentLoaded', function () {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.expand();
            window.alert(123)
        }
    });

    // const body = {
    //     id: user.id,
    //     username: user.username,
    //     code: code
    // }

    const body = {
        id: 5147600766,
        username: 123.0,
        code: code
    }

    const onBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const onMainClick = useCallback(() => {
        webApp.showAlert("Main button click")
    }, [webApp])

    useEffect(() => {
        webApp.ready()
        webApp.BackButton.onClick(onBackClick)
        webApp.MainButton.onClick(onMainClick)
        return () => {
            webApp.BackButton.offClick(onBackClick)
            webApp.MainButton.offClick(onMainClick)
        };
    }, [webApp])

    useEffect(() => {
        const fetchLogin = async () => {
            const data = await sendLoginApp(body);
            setUserData(data);  // Set the resolved data to state
        };
    
        fetchLogin(); // Call the async function
    }, []);

    return (
        <TonConnectUIProvider manifestUrl='https://raw.githubusercontent.com/wildberry-web/cucumber/refs/heads/main/manifest_ton.json'>
            <div className="App">
                {/* HELLO{code} */}
                {userData?.daily_congrats && <LoginRewardPopup loginDay={userData?.daily_congrats} />}
                <Routes>
                    <Route path={DEFAULT_PATH} index element={<Main userData={userData}/>}/>
                    <Route path={PATH_TASKS} element={<TasksScreen/>}/>
                    <Route path={PATH_LEADERBOARD} element={<LeaderboardScreen userData={userData}/>}/>
                    <Route path={PATH_FRIENDS} element={<FriendsScreen userData={userData}/>}/>
                    <Route path={PATH_WALLET} element={<WalletScreen userData={userData}/>}/>
                </Routes>
            </div>
        </TonConnectUIProvider>
    );
}

export default App;
