import React, {useState, useCallback, useEffect} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import AllTabs from "../../components/kit/Tabs/AllTabs";
import { useTonWallet, useTonConnectUI, useTonAddress, UserRejectsError } from '@tonconnect/ui-react';
import { useSearchParams } from 'react-router-dom';


const WalletScreen = ({userData}) => {

    const wallet = useTonWallet();
    const [tonConnectUI, setOptions] = useTonConnectUI()
    const [tonWalletAddress, setTonWalletAddress] = useState(null)
    const userFriendlyAddress = useTonAddress();
    const [searchParams, setSearchParams] = useSearchParams();
    let isTransaction = searchParams.get("transaction");
    let isConnect = searchParams.get("connect");

    const handleWalletConnect = useCallback(address => {
        setTonWalletAddress(address)
    }, [])

    const handleWalletDisconnect = useCallback(address => {
        setTonWalletAddress(null)
    }, [])

    useEffect(() => {
        const checkWalletConnection = async () => {
            if (tonConnectUI.account?.address) {
                handleWalletConnect(tonConnectUI.account?.address)
            } else {
                handleWalletDisconnect()
            }
        }

        checkWalletConnection()

        const unsubscribe = tonConnectUI.onStatusChange(wallet => {
            if (wallet) {
                handleWalletConnect(wallet.account.address)
            } else {
                handleWalletDisconnect()
            }
        })

        return () => {
            unsubscribe()
        }

    }, [tonConnectUI,handleWalletConnect,handleWalletDisconnect])

    const handleWalletAction = async () => {
        if (tonConnectUI.connected) {
            await tonConnectUI.disconnect()
        } else {
            await tonConnectUI.openModal()
        }
    }

    const formatAddress = (address) => {
        if (address){
            return `${address?.slice(0,4)}...${address?.slice(-4)}`
        }
    }

    const transaction = {
        messages: [
            {
                address: "0:95e5d2850799068964d88b6881b8e9e75b37d3f7b05f78a0579e3bed88dab827", // destination address
                amount: "10000000" //Toncoin in nanotons
            }
        ]
    
    }

    // REWRITE WITH BACKEND CHECK
    const completeTaskValidate = (result) => {
        if (result){
            // result = {"boc": "hash=="}
            console.log(result)
        }
    }

    const sendTransaction = async () => {
        try {
            let result = await tonConnectUI.sendTransaction(transaction)
            completeTaskValidate(result)
            console.log(12234)
        } catch (e){
            // console.log(e.message)
        }
    }

    useEffect(() => {
        if (!tonWalletAddress && isConnect) {
            handleWalletAction()
        } 
    }, [isConnect])

    useEffect(() => {
        if (tonWalletAddress && isTransaction) {
            sendTransaction()
        } 
    }, [isTransaction, tonWalletAddress])

    useEffect(() => {
        console.log(userData?.wallet)
        if (userData?.wallet && tonWalletAddress && userData?.wallet !== tonWalletAddress) {
            console.log("WALLET: ",userData.wallet)
            console.log("WALLET ACTION: ",tonWalletAddress)
            //SEND WALLET TO BACKEND
        }
    }, [tonWalletAddress,userData])

    return (
        <TelegramScreen showbackbutton={true}>
            <TelegramHeader>
                <TelegramText className='telegramTitle'>Wallet 💰</TelegramText>
            </TelegramHeader>

                {!tonWalletAddress ? (
                    <div>
                        <h3>Connect your Ton Wallet</h3>
                        <button className='telegramButton' onClick={handleWalletAction}>Connect Wallet</button>
                    </div>
                ) : (
                    <div>
                        <p>You successfully connected your <b>{wallet?.name}</b></p>
                        <p>{formatAddress(userFriendlyAddress)}</p>
                        <button className='telegramButton' onClick={handleWalletAction}>Disconnect Wallet</button>
                    </div>
                )}
            
            <AllTabs/>

        </TelegramScreen>
    );
};

export default WalletScreen;
