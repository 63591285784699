import React from 'react';
import './SingleTab.css';
import {useNavigate} from "react-router-dom";

const SingleTab = ({props}) => {

    const navigate = useNavigate();

    return (
        <div className={'single_tab ' + props.className} onClick={() => navigate(props.path)}>
            <img src={props.icon} height="25px"/>
            <p>{props.tabName}</p>
        </div>
    );
};

export default SingleTab;
